import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Header = () =>{
    const [menu, setMenu] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');

    useEffect(() => {
        axios.get('https://valymar.com.uy/cms/consultas?key=AD944C298CA11FE355C83C4DAA96C&menu=true&lang='+lang)
      .then(res => {
          const menu_data = res.data;
          setMenu(menu_data);
        }) 
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
},[lang]);

if(loading){
    return(null);
}
    return(
        <div id="header"> 

      <div className="lang">
                <div className="en"><Link to={`/?lang=en`}><img src="/img/en.jpg" alt="en"></img></Link></div>
                <div className="es"><Link to={`/?lang=es`}><img src="/img/es.jpg" alt="es"></img></Link></div>
    </div>  
            <div className="logo"><Link to={`/${search}`}><img src="/img/logo.png" alt="logo"></img></Link></div>
            <div className="menu">
                <ul>
                    <li className="pez"><Link to={`/${search}`}><img src="/img/pescadito.svg" alt="icon"></img></Link></li>
                    <li className="menu-item"><NavLink to={`/nosotros/${search}`} activeClassName="active">{lang === "en" ? "US" : "NOSOTROS"}</NavLink></li>
                    <li className="menu-item" id="mercado">
                        <Link to={search}>{lang === "en" ? "INTERNAL MARKET" : "MERCADO INTERNO"}</Link>
                        <ul id="contmenu">
                        {menu.map((menuli)=> (
                             <li className="submenu" key={menuli.id}><NavLink to={`/mercado-interno/categoria/${menuli.url}/${search}`} activeClassName="active">{menuli.name}</NavLink></li>
                        ))}
                        </ul>
                    </li>
                    <li className="menu-item"><NavLink to={`/mercado-exterior/${search}`} activeClassName="active">{lang === "en" ? "OUTSIDE MARKET" : "MERCADO EXTERIOR"}</NavLink></li>
                    <li className="menu-item"><NavLink to={`/encontranos-en/${search}`} activeClassName="active">{lang === "en" ? "FIND US" : "ENCONTRANOS EN"}</NavLink></li>
                </ul>
            </div>
            <div className="social">
                <div className="fb"><a href="https://www.facebook.com/FrigorificoValymar/" target="_blank" rel="noreferrer"><img src="/img/fb.png" alt="fb"></img></a></div>
                <div className="in"><a href="https://www.instagram.com/valymar.uy/?hl=es" target="_blank" rel="noreferrer"><img src="/img/ins.png" alt="in"></img></a></div>
            </div>
        </div>
    );
}

export default Header;