import React, { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';

const Nosotros = () =>{
    const [nosotros, setNosotros] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');

    useEffect(() => {
        axios.get('https://valymar.com.uy/cms/consultas?key=AD944C298CA11FE355C83C4DAA96C&nosotros=true&lang='+lang)
      .then(res => {
          const nosotros_data = res.data;
          setNosotros(nosotros_data);
        }) 
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
},[]);

if(loading){
    return(null);
}
    return(
        <div className="nosotros">
            <h2>{lang === "en" ? "US" : "NOSOTROS"}</h2>
            {parse(nosotros[0].texto)}
            </div>
    );
}

export default Nosotros;