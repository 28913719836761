import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';


const Footer = () => {
    const [marca, setMarcas] = useState([{}]);
    const [loading, setLoading] = useState(true);

    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');

    useEffect(() => {
        axios.get('https://valymar.com.uy/cms/consultas?key=AD944C298CA11FE355C83C4DAA96C&marca=true')
      .then(res => {
          const marcas_data = res.data;
          setMarcas(marcas_data);
        }) 
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
},[lang]);

if(loading){
    return(null);
}

    return(
        <div id="footer">
            <div className="title"><h2>
                {lang === "en" ? "UR CLIENTS PREFER US" : " EMPRESAS QUE NOS PREFIEREN"}
                </h2></div>
            <div className="marcas">
                {marca.map((empresa)=> (
                     <div className="marca" key={empresa.id}><img src={empresa.img} alt={empresa.name}></img></div>
                ))}
            </div>
            <div className="copyright">
                <p>MONTEVIDEO - URUGUAY - TEL: +598 2 508 02 43 / 86 27 - CNO. VERACIERTO 3078 - VALYMAR@VALYMAR.COM.UY</p>
            </div>
        </div>
    );
}

export default Footer;