import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const EncontranosEn = () => {
    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');
    const [status, setStatus] = useState(lang === "en" ? "send" : "enviar");
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus(lang === "en" ? "sending..." : "enviando...");
      const { nombre, apellido, email, phone, asunto, message } = e.target.elements;
      let details = {
        nombre: nombre.value,
        apellido: apellido.value,
        email: email.value,
        phone: phone.value,
        asunto: asunto.value,
        message: message.value,
      };
        await fetch("https://valymar.com.uy/cms/envio.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Enviado");
    };
    return(
        <div id="encontranos">
            <div className="img-contacto">
                <img src="/img/camion.jpg" alt="contacto"></img>
            </div>
            <div className="contacto-content">
                <div className="contacto-box">
                    <h2>{lang === "en" ? "Send consult" : "Enviar consulta"}</h2>
                    <form onSubmit={handleSubmit}>
                <div className="input">
                <input type="text" id="nombre" placeholder={lang === "en" ? "name" : "nombre"} required />
                </div>
                <div className="input">
                <input type="text" id="apellido" placeholder={lang === "en" ? "last name" : "apellido"} />
                </div>
                <div className="input">
                <input type="email" id="email" placeholder="email" required />
                </div>
                <div className="input">
                <input type="text" id="phone" placeholder={lang === "en" ? "phone" : "teléfono"}/>
                </div>
                <div className="clear"></div>
                <div className="asunto">
                <input type="text" id="asunto" placeholder={lang === "en" ? "affair" : "asunto"} />
                </div>
                <div className="clear"></div>
                <div className="textarea">
                <textarea id="message" placeholder={lang === "en" ? "message" : "mensaje"} required />
                </div>
                <button type="submit">{status}</button>
                </form>
                </div>
                <div className="contacto-box" style={{paddingTop:'4vw'}}>
                    <div className="social-box"><img src="/img/face.svg" alt="facebook"></img><a href="https://www.facebook.com/Frigorificopesquero/">Valymar</a></div>
                    <div className="social-box"><img src="/img/instagram.svg" alt="instagram"></img><a href="https://www.instagram.com/valymar.uy/?hl=es">Valymar.uy</a></div>
                    <div className="social-box"><img src="/img/celular.svg" alt="celular"></img>+ 598 2 508 02 43 / 86 27</div>
                    <div className="social-box"><img src="/img/email.svg" alt="email"></img><a href="mailto:ventas@valymar.com.uy">ventas@valymar.com.uy</a></div>
                    <div className="social-box"><img src="/img/direccion.svg" alt="direccion"></img>Cno. Veracierto 3078</div>
                </div>
                <div className="clear"></div>
            </div>
        </div>
    );
}

export default EncontranosEn;