import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';

const MercadoExterior = () => {
    const [exterior, setExterior] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');

    useEffect(() => {
        axios.get('https://valymar.com.uy/cms/consultas?key=AD944C298CA11FE355C83C4DAA96C&mercado-exterior=true&lang='+ lang)
      .then(res => {
          const nexterior_data = res.data;
          setExterior(nexterior_data);
        }) 
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
},[]);

if(loading){
    return(null);
}
    return(
        <div className="mercado-exterior">
        <h1>{lang === "en" ? "OUTSIDE MARKET" : "MERCADO EXTERIOR"}</h1>
        <div className="mercado-exterior-img"><img src={exterior[0].img} alt={exterior[0].name}></img></div>
        {parse(exterior[0].texto)}
        </div>
    );
}

export default MercadoExterior;