import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const Producto = () => {
    const [producto, setProducto] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const { pro } = useParams();
    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');
    const history = useHistory();

    useEffect(() => {
        axios.get('https://valymar.com.uy/cms/consultas?key=AD944C298CA11FE355C83C4DAA96C&producto=' + pro +'&lang='+lang)
      .then(res => {
          const producto_data = res.data;
          setProducto(producto_data);
        }) 
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
},[pro]);

if(loading){
    return(null);
}
    return(
        <div className="producto">
            <div>
                <button className="goback" onClick={() => { history.goBack(); }}>
                {lang === "en" ? "< Go Back" : "< Volver"}
                </button>
            </div>
            {producto.map(prod => (
                <React.Fragment key={prod.id}>
                 <h1>{prod.name}</h1>
                 <div className="producto-box">
                     <img src={prod.img} alt={prod.name}></img>
                 </div>
                 <div className="producto-box">
                     {parse(prod.texto)} 
                 </div>
                 <div className="clear"></div>
                 {prod.galUno !== "" ? 
                 <div className="gallery">
                    <div className="gal"><img src={prod.galUno} alt={prod.name}></img></div>
                    <div className="gal"><img src={prod.galDos} alt={prod.name}></img></div>
                    <div className="gal"><img src={prod.galTres} alt={prod.name}></img></div>
                    </div>
                    :
                    <div className="gallery"></div>
                 }
                 </React.Fragment>
            ))}
            <div className="clear"></div>
        </div>
    );
}

export default Producto;