import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

const Categoria = () => {
    const [categoria, setCategoria] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const [titulo, setTitulo ] = useState([{"category":""}]);
    const { cat } = useParams();
    const { search } = useLocation();
    var lang = new URLSearchParams(search).get('lang');

    useEffect(() => {
        axios.get('https://valymar.com.uy/cms/consultas?key=AD944C298CA11FE355C83C4DAA96C&categoria=' + cat +'&lang='+lang)
      .then(res => {
          const categoria_data = res.data;
          setCategoria(categoria_data);
          setTitulo(categoria_data[0].category);
        }) 
        .catch(err =>  console.log(err))
        .finally(() => {
          setLoading(false);
        });
    },[cat]);

if(loading){
    return(null);
}
    return(
        <div className="categorias">
            <h1>{titulo}</h1>
        {categoria.map((cat)=> (
            <React.Fragment key={cat.id}>
            <div className="categoria" >
                <Link to={`/mercado-interno/producto/${cat.url}/${search}`}>
                <img src={cat.img} alt={cat.name}></img>
                <div className="nombre">
                    <h3>{cat.name}</h3>
                </div>
                </Link>
            </div>
            </React.Fragment>
       ))}
       <div className="clear"></div>
       </div>
    );
}

export default Categoria;