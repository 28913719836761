import React from 'react';
import { useLocation } from 'react-router-dom';

const Home = () => {
    const search = useLocation().search;
    var lang = new URLSearchParams(search).get('lang');
    return(
        <div className="main">
            <div className="title"><h2>{lang === "en" ? "IN FISH AND SEAFOOD YOUR BEST CHOICE" : "EN PESCADOS Y MARISCOS SU MEJOR ELECCIÓN"}</h2></div>
            <div className="box-home">
                <div className="img-box"><div className="mask"><img src="/img/FRITURAS.jpg" alt="frituras"></img></div></div>
                <div className="img-box"><div className="mask"><img src="/img/SALMON.jpg" alt="salmon"></img></div></div>
                <div className="img-box"><div className="mask"><img src="/img/MARISCOS.jpg" alt="mariscos"></img></div></div>
                <div className="img-box"><div className="mask"><img src="/img/CAMARONES.jpg" alt="camarones"></img></div></div>
                <div className="clear"></div>
            </div>
        </div>
    );
}

export default Home;