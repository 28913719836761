import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Nosotros from './pages/Nosotros'
import Categoria from './pages/Categoria';
import Producto from './pages/Producto';
import MercadoExterior from './pages/MercadoExterior';
import EncontranosEn from './pages/EncontranosEn';
import Header from './components/Header';
import Footer from './components/Footer';


const App = () => {
    return(
        <Router>
            <Header />
        <Switch>
            <Route path="/" exact>
                <Home />
            </Route>
            <Route path="/nosotros">
               <Nosotros />
            </Route>
            <Route path="/mercado-interno/categoria/:cat" exact>
                <Categoria />
            </Route>
            <Route path="/mercado-interno/producto/:pro" exact>
                <Producto />
            </Route>
            <Route path="/mercado-exterior">
                <MercadoExterior />
            </Route>
            <Route path="/encontranos-en">
                <EncontranosEn />
            </Route>
        </Switch>
        <Footer />
        </Router>
    );
}

export default App;